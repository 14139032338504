import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFileUpload from './useFileUpload';
import useApi from './useApi';
import { generateId } from '../util/index';
import { createImage, updateImage } from '../modules/images';
import { deleteImage } from '../actions/images';
import { updateStickersByImageId } from '../actions/stickers';
import { updateElementsByImageId } from '../actions/workspace';
import {
  getDataUrl,
  computeImageDimensions,
  resizeImage,
  preloadImages,
  computeNativeImageSize,
} from '../util/images';
import { getStickerPositionFromFace } from '../util/faceapi';
import { createWarning } from '../actions/notifications';
import { selectCurrentAlbum } from '../selectors/albums';
import { getImage } from '../selectors/images';
import useLocale from './localization/useLocale';
import useLoading from './useLoading';

export const imageModels = {
  sticker: 'sticker',
  image: 'image',
};

export const defaultValidators = {
  size: [0.0001, 16],
  type: ['image/jpeg', 'image/png', 'image/svg+xml'],
  totalPixels: 32000000,
};

/**
 * Thunk action creator that schedules an image upload
 * @param {string} id - The client-side image ID
 * @param {File} file - The file to upload
 * @param {Function} scheduleUpload - The upload function
 */
const executeScheduleUpload = (id, file, scheduleUpload) => async (
  dispatch,
  getState
) => {
  const clientImage = getImage(getState(), id);
  return scheduleUpload(file, clientImage);
};

/**
 * Hook for handling image uploads with face detection
 * @param {Object} options - Configuration options
 * @param {Object} options.validators - Image validation rules
 * @returns {Object} Image upload methods
 */
function useImageUpload({ validators = defaultValidators } = {}) {
  const { t } = useLocale();
  const { upload } = useFileUpload();
  const currentAlbum = useSelector(selectCurrentAlbum);
  const { startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const api = useApi();

  const endpoint = `/albums/${currentAlbum}/images`;

  /**
   * Replace a client image with server image and update references
   */
  const replaceImage = useCallback(
    async ({ clientId, serverImage, model }) => {
      const { id: serverId } = serverImage;

      /**
       * Cache the small versions before replacing the image to avoid flickering.
       */
      const { small, medium } = serverImage.blob || {};
      await preloadImages([small, medium].filter(Boolean));

      // Update image in the store
      dispatch(updateImage(clientId, serverImage));

      // Update references to the image based on model type
      if (model === imageModels.sticker) {
        dispatch(
          updateStickersByImageId(clientId, {
            image: serverId,
            ...getStickerPositionFromFace(
              serverImage.details.face,
              computeNativeImageSize(serverImage)
            ),
          })
        );
      } else if (model === imageModels.image) {
        dispatch(updateElementsByImageId(clientId, serverId));
      }
    },
    [dispatch]
  );

  /**
   * Schedule an image upload to the server
   */
  const scheduleUpload = useCallback(
    async (file, clientImage) => {
      const { id: clientId, details, model } = clientImage;

      try {
        startLoading(clientId);
        const { id: blobId } = await upload(file);

        const {
          data: { image: serverImage },
        } = await api.post(endpoint, {
          model,
          blob_id: blobId,
          details,
        });

        await replaceImage({
          clientId,
          serverImage,
          model,
        });

        stopLoading(`image-${clientId}`);

        return serverImage.id;
      } catch (err) {
        dispatch(
          createWarning(
            t('editor.imageUpload.uploadError', { fileName: file.name })
          )
        );
        dispatch(deleteImage(clientId));
        throw err;
      } finally {
        stopLoading(`image-${clientId}`);
      }
    },
    [
      api,
      endpoint,
      replaceImage,
      upload,
      dispatch,
      t,
      startLoading,
      stopLoading,
    ]
  );

  /**
   * Validate a file against size and type constraints
   */
  const validateFile = useCallback(
    file => {
      const errors = [];

      const sizeMb = file.size / 1000 / 1000;
      const [min, max] = validators.size;

      if (sizeMb < min || sizeMb > max) {
        errors.push(t('editor.imageUpload.sizeError', { min, max }));
      }

      if (!validators.type.includes(file.type)) {
        errors.push(
          t('editor.imageUpload.typeError', {
            types: validators.type.join(', '),
          })
        );
      }

      return errors;
    },
    [validators, t]
  );

  /**
   * Validate image dimensions
   */
  const validatePixels = useCallback(
    ({ width, height }) => {
      const errors = [];

      if (width * height > validators.totalPixels) {
        errors.push(
          t('editor.imageUpload.dimensionsError', {
            maxPixels: validators.totalPixels.toLocaleString(),
          })
        );
      }

      return errors;
    },
    [validators, t]
  );

  /**
   * Create a client-side image object with upload capabilities
   */
  const createClientImage = useCallback(
    async (file, model) => {
      // Validate file
      const fileErrors = validateFile(file);
      if (fileErrors.length > 0) {
        return { errors: fileErrors };
      }

      const originalDataUrl = await getDataUrl(file);
      const { width, height } = await computeImageDimensions(originalDataUrl);

      // Validate dimensions
      const pixelError = validatePixels({ width, height });
      if (pixelError.length > 0) {
        return { errors: pixelError };
      }

      // Create client-side image
      const id = generateId();

      const previewDataUrl = await resizeImage(originalDataUrl, 800);

      const clientImage = {
        id,
        model,
        blob: {
          filename: file.name,
          full: previewDataUrl,
          large: previewDataUrl,
          medium: previewDataUrl,
          original: originalDataUrl,
          small: previewDataUrl,
        },
        details: {
          width,
          height,
        },
        meta: {},
        tags: [],
      };

      // Add to store and remove from loading queue
      dispatch(createImage(clientImage));

      /**
       * Cancel the upload process
       */
      const cancelUpload = () => dispatch(deleteImage(id));

      return {
        clientImage,
        cancelUpload,
        file,
      };
    },
    [dispatch, validateFile, validatePixels]
  );

  /**
   * Handle the image upload process
   */
  async function handler(file, model) {
    const { errors, clientImage } = await createClientImage(file, model);

    if (errors) {
      dispatch(createWarning(errors.join(', ')));
      return null;
    }

    // Start upload and get a promise that resolves when upload completes
    // For sticker images, the server will perform face detection
    dispatch(executeScheduleUpload(clientImage.id, file, scheduleUpload));

    // Return immediately without waiting for upload
    return clientImage;
  }

  /**
   * Create a sticker image with face detection (performed by server)
   */
  const createStickerImage = file => handler(file, imageModels.sticker);

  /**
   * Create a workspace image
   */
  const createWorkspaceImage = file => handler(file, imageModels.image);

  return {
    createWorkspaceImage,
    createStickerImage,
    createClientImage,
  };
}

export default useImageUpload;
